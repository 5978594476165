import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflowY:'scroll',
  height:'80%',
  p: 4,
};

export default function KeepMountedModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="d-inline">
      <Button onClick={handleOpen} className="p-0">Politique de confidentialité</Button>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title politiqueText" variant="h6" component="h2">
          POLITIQUE DE CONFIDENTIALITÉ
          <CloseIcon
          onClick={handleClose}
          className="float-right"
          >
            Close
          </CloseIcon>
          </Typography>
          <hr />
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            <p>
            L’Université Privée de Marrakech porte une attention particulière à la protection des données personnelles de ses utilisateurs
            </p>
            <p>
            Conformément à la loi n° 09-08 promulguée par le Dahir 1-09-15 du 18 février 2009, relative à la protection des personnes physiques à l’égard du traitement de données à caractère personnel, l’utilisateur bénéficie d’un droit d’accès et de rectification aux informations le concernant. Pour exercer ce droit, l’utilisateur peut s’adresser par mail à l’UPM : <a href="mailto:contact@upm.ac.ma">contact@upm.ac.ma</a>
            </p>
            <p>
            La sécurité des données à caractère personnel fait partie des priorités de l’Université Privée de Marrakech qui s’engage à prendre toutes les mesures nécessaires sur le plan administratif et technique pour empêcher toute divulgation, utilisation, altération ou destruction des données à caractère personnel qu’un utilisateur lui fournit (contrôle des accès, sécurisation des mots de passe, accès selon des habilitations spécifiques, etc.).
            </p>
            <h5 className="fw-bold">
            CONDITIONS DE LA COLLECTE DE DONNÉES
            </h5>
            <p>
            Les services l’Université Privée de Marrakech sont amenés, dans le cadre de leurs activités, à mettre en œuvre des traitements en collectant vos données à partir notamment des sources suivantes :
            </p>
            <ul>
              <li>
              Le formulaire de demande d’information.
              </li>
              <li>
                Le formulaire de téléchargement de brochure.
              </li>
              <li>
                Le formulaire de dépôt de candidatures à l’un de programmes de formation
              </li>
              <li>
                Le formulaire d’inscription à la newsletter
              </li>
              <li>
                Tout autre formulaire présent sur les sites de l’Université Privée de Marrakech
              </li>
            </ul>
            <p>
            Les données collectées sont uniquement destinées à la gestion des demandes d’informations, dépôts de candidatures, ou à faire parvenir des informations aux personnes abonnées à la newsletter de l’Université
            </p>
            <h5 className="fw-bold">CATÉGORIES DE DONNÉES PERSONNELLES COLLECTÉES ET TRAITÉES</h5>
            <p>
            Dans le cadre de ses activités, l’Université Privée de Marrakech est amenée à collecter les données personnelles suivantes :
            </p>
            <ul>
              <li>
              Nom et prénom
              </li>
              <li>
                Adresse électronique.
              </li>
              <li>
                Numéro de téléphone.
              </li>
              <li>
                Numéro de téléphone parents.
              </li>
              <li>
                Adresse email.
              </li>
              <li>
                Date et lieu de naissance.
              </li>
              <li>
                Adresse postale.
              </li>
              <li>
                Numéro de CIN (dans le cas d’un dépôt de candidature à l’un de nos programmes).
              </li>
              <li>
                Informations relatives à votre parcours scolaire et / ou universitaire.
              </li>
              <li>
                Données personnelles contenues éventuellement dans le message de demande de renseignements.
              </li>
              <li>
                Données de connexion.
              </li>
            </ul>
            <p>
            L’Université Privée de Marrakech veille à respecter le principe de minimisation des informations et à ne collecter que les données personnelles nécessaires au traitement des demandes des visiteurs de ses sites.
            </p>
            <h5 className="fw-bold">DESTINATAIRES DES DONNÉES</h5>
            <p>
            L’Université Privée de Marrakech est la seule destinataire et utilisatrice des données collectées.<br />
            L’Université Privée de Marrakech s’engage à ne céder ou commercialiser ces données à aucune tierce partie.
            </p>
            <h5 className="fw-bold">
              VOS DROITS RELATIFS AU TRAITEMENT DE VOS DONNÉES PERSONNELLES
            </h5>
            <p>
            Conformément à la réglementation en vigueur, vous disposez du droit d’accéder à vos données personnelles, de les rectifier, de vous opposer à leur traitement ou d’en obtenir la limitation ou leur suppression.
            </p>
            <p>
            Pour pouvoir exercer ces droits, contactez l’Université Privée de Marrakech sur 
             <a href="mailto:contact@upm.ac.ma"> contact@upm.ac.ma</a>
            </p>
            <div className="text-end">
              <Button variant="contained" onClick={handleClose}>
                Fermer
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
