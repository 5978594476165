import React, { useEffect, useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import axios from 'axios'
export default function GroupedSelect({changeFormation,errorFilere}) {
  const [poles,setPoles]=useState([])
  useEffect(()=>{
    axios.get('https://lemonmind.agency/lpupmbackend/poles.php')
    .then(res=>{
      setPoles(res.data);
    })
    .catch(err=>console.log(err))
  },[poles])

    const HandleChange=(e)=>{
      const formation=e.target.value.split('+')
        changeFormation(formation[0])
    }

  return (
    <>
      <FormControl size="small" error={errorFilere} fullWidth className="SelectForamtion bg-input">
        <InputLabel htmlFor="grouped-select">Pôles de formation</InputLabel>
        <Select   defaultValue="" onChange={HandleChange} id="grouped-select" label="pôles de formation">
            <MenuItem value="">
              <em>Pôles de formation</em>
            </MenuItem>
            {poles.map((pole) => (
              <MenuItem value={pole.id+"+"+pole.name} key={pole.id}>
                  {pole.name}
             </MenuItem> 
              ))}
            {/*
            <MenuItem value="Ingénierie et Innovation">
                Ingénierie et Innovation
            </MenuItem>
            <MenuItem value="Sciences de la Santé">
                Sciences de la Santé
            </MenuItem>
            <MenuItem value="Sport &amp; Management">
                 Sport &amp; Management
            </MenuItem>
            <MenuItem value="Arts, Culture, Médias et Audiovisuel">
                Arts, Culture, Médias et Audiovisuel
            </MenuItem>
            <MenuItem value="Faculté de médecine">
                Faculté de médecine
            </MenuItem> */}
       </Select>
            <FormHelperText className="text-danger">
                {errorFilere}
            </FormHelperText>
      </FormControl>
    </>
  );
}
