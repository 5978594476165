import React from 'react'
import Banner from './components/Banner'
import WhyUpm from './components/WhyUpm'
import Header from './layout/Header'
import Footer from './layout/Footer'

const App = () => {
  return (
    <>
       <div className='MainHeader' id="contacter">   
         <Header /> 
         <Banner /> 
       </div> 
      <WhyUpm />
      <Footer /> 
    </>
  )
}

export default App