import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import React, { useEffect,useState } from 'react'

const SelectFormationWithPole = ({poleId,changeFormationWithPole,errorfilieres}) => {
    const [formation,setFormation]=useState([])
    const [formationLp,setFormationLp]=useState('')
    const [formationvalue,setFormationValue]=useState('')
    useEffect(()=>{
        if(poleId){
            axios.get(`https://lemonmind.agency/lpupmbackend/formation.php?id=${poleId}`)
            .then(res=>{
                setFormation(res.data);
            })
            .catch(err=>console.log(err))
        }else{
            setFormation([])
        }
    },[poleId])
    const HandleChange=(e)=>{
        const formation=e.target.value.split('+')
        setFormationValue(formation[0])
        changeFormationWithPole(formation[1])
        setFormationLp(formation[2])
    }
  return (
    <>
        <input type="text" value={formationLp}  className="d-none" name="formation Lp"/>
        <input type="text" name="formation" className='d-none' value={formationvalue} />
        <FormControl  size="small" error={errorfilieres} fullWidth className="SelectForamtion bg-input">
            <InputLabel htmlFor="grouped-select">Programme de formation</InputLabel>
            <Select onChange={HandleChange} defaultValue="" id="grouped-select" label="Programme de formation">
             {formation.map((pole) => (
              <MenuItem value={pole.correspondance_crm + "+" + pole.id+"+"+pole.name} key={pole.id}>
                  {pole.name}
             </MenuItem> 
              ))}
            </Select>
        </FormControl>
        <FormHelperText className="text-danger">
                {errorfilieres}
        </FormHelperText>
    </>
  )
}

export default SelectFormationWithPole