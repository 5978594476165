import React, { useState } from 'react'
import { Row,Col }  from 'react-bootstrap'
import { Checkbox, FormControlLabel,Button,TextField, FormControl, MenuItem } from "@mui/material";
import Politique from './Politique'
import SelectFormation from './SelectFormation'
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import SelectFormationWithPole from './SelectFormationWithPole';
import NiveauSouhaite from './NiveauSouhaite';

const FormMedcin = () => {
    const [errorCivillete,setErrorCivillete]=useState('')
    const [errorLastName,setErrorLastName]=useState('')
    const [errorFirstname,setErrorFirstname]=useState('')
    const [errorEmail,setErrorEmail]=useState('')
    const [errorPhone,setErrorPhone]=useState('')
    const [errorVilles,setErrorVilles]=useState('')
    const [errorPolitique,setErrorPolitique]=useState('')
    const [politique,setPolitique]=useState(true)
    const [success,setSuccess]=useState('')
    const [errorPole,setErrorPole]=useState('')
    const [errorFormation,setErrorFormation]=useState('')
    const [errorNiveau,setErrorNiveau]=useState('')
    const [lead,setLead]=useState({
        civillete:"M",
        firstname:"",
        lastname:"",
        email:"",
        phone:"",
        pole:"",
        formation:"",
        niveau:"",
        villes:"",
    })
    const HandleChange=(e)=>{
        const target = e.target;
        setLead({...lead,[target.name]:target.value});
      }
      const changeFormation=(e)=>{
        setLead({...lead,pole:e})
      }
      const changeFormationWithPole=(e)=>{
        setLead({...lead,formation:e})
      }
      const HandleChangePolitique=(e)=>{
        setPolitique(!politique)
      }
      const ChangeVille=(e)=>{
       setLead({...lead,villes:e.target.value});
      }
      const changeNiveau=(e)=>{
        setLead({...lead,niveau:e})
      }
      const submitForm=(e)=>{
        e.preventDefault()
        setErrorCivillete('')
        setErrorFirstname('')
        setErrorLastName('')
        setErrorEmail('')
        setErrorPhone('')
        setErrorPole('')
        setErrorFormation('')
        setErrorNiveau('')
        setErrorVilles('')
        if(lead.civillete === ""){
            return setErrorCivillete('Civillete is required')
         }
        let stirngReg = /^[aA-zZ\s]+$/
        if(lead.lastname === ""){
           return setErrorLastName('Nom est obligatoire')
         }
        if(!stirngReg.test(lead.lastname )){
           return setErrorLastName('Format incorrect')
         }
        if(lead.firstname === ""){
           return setErrorFirstname('Prénom est obligatoire')
         }
        if(!stirngReg.test(lead.firstname )){
           return setErrorFirstname('Format incorrect')
         }
        if(lead.email === ""){
           return setErrorEmail('Email est obligatoire')
         }
         let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regEmail.test(lead.email)){
           return setErrorEmail('Format incorrect')
         }
        if(lead.phone === "" ){
           return setErrorPhone('Téléphone obligatoire')
         }
        if(lead.phone.substring(0,3) === "212" && lead.phone.length !== 12){
          return setErrorPhone('Format incorrect')
          }
        if(lead.pole === ""){
            return setErrorPole('Pole est obligatoire')
          }
        if(lead.formation === ""){
          return setErrorFormation('Pogramme de Foramtion est obligatoire')
        }
        if(lead.niveau === ""){
          return setErrorNiveau('Niveau est obligatoire')
        }
        if(lead.villes === ""){
          return setErrorVilles('Ville est obligatoire')
        }
        if(politique === true){
          return setErrorPolitique('Veuillez accepter les mention légales !')
        }
        window && window.dataLayer && window.dataLayer.push({
          'event':'form_submit_success',
          'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
      });
        setSuccess('Votre candidature pour le programme médecine a bien été enregistrée Le service des Admissions vous contactera pour compléter votre');
      }
      const handleOnChange = (args) => {
       setLead({...lead,phone:args})
      };
  return (
    <form onSubmit={submitForm}>
                {success
                  ? 
                  <div className='text-success px-5'>
                    Votre candidature a bien été enregistrée, <br />
                     nous allons vous contacter dans les plus brefs délais.
                  </div>
                  
                  :
                  <>
                  <Row>
                  <Col md={12} xs={12}  className="inputClass">
                    <FormControl>
                      <RadioGroup
                            row
                            size="small"
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="civillete"
                            error={errorCivillete}
                            className='justify-content-center align-items-center'
                            onChange={HandleChange}
                            defaultValue="M"
                          >
                            <FormLabel className="mx-2">Civilité :</FormLabel>
                            <FormControlLabel value="M"  control={<Radio />} label="M" />
                            <FormControlLabel value="Mme" control={<Radio />} label="Mme" />
                            <FormControlLabel value="Mlle" control={<Radio />} label="Mlle" />
                      </RadioGroup>
                    </FormControl>
                </Col>
                <Col  xs={6} md={6} lg={6} className="inputClass">
                    <TextField  
                        size="small"
                        autoComplete="off" 
                        className='bg-input' 
                        onChange={HandleChange} 
                        helperText={errorLastName}  
                        error={errorLastName} 
                        value={lead.lastname} 
                        name="lastname" 
                        label="Nom" 
                        variant="outlined" 
                        fullWidth 
                    />
                </Col>
                <Col xs={6} md={6} lg={6} className="inputClass">
                    <TextField  
                        size="small"
                        autoComplete="off" 
                        className='bg-input' 
                        onChange={HandleChange} 
                        helperText={errorFirstname}  
                        error={errorFirstname} 
                        value={lead.firstname} 
                        name="firstname" 
                        label="Prénom" 
                        variant="outlined" 
                        fullWidth 
                    />
                </Col>
                <Col md={12}>
                    <TextField 
                        size="small"
                        autoComplete="off" 
                        className='bg-input' 
                        onChange={HandleChange} 
                        helperText={errorEmail}  
                        error={errorEmail}  
                        name="email" 
                        value={lead.email} 
                        label="Email" 
                        variant="outlined" 
                        fullWidth 
                    />
                </Col>
                <Col md={12}>
                    <ReactPhoneInput error={errorPhone}  className='bg-input' onChange={handleOnChange} country={"ma"} value="212"  regions={['africa', 'europe']} />
                    <FormHelperText className="text-danger">{errorPhone}</FormHelperText>
                </Col>
                <Col md={12}>
                    <SelectFormation 
                        changeFormation={changeFormation} 
                        errorFilere={errorPole} 
                    />
                </Col>
                <Col md={12}>
                   <SelectFormationWithPole 
                      poleId={lead.pole} 
                      errorfilieres={errorFormation} 
                      changeFormationWithPole={changeFormationWithPole} 
                   />
                </Col>
                <Col md={12}>
                   <NiveauSouhaite 
                   formation_id={lead.formation} 
                   changeNiveau={changeNiveau}
                   errorNiveau={errorNiveau}
                   />
                </Col>
                <Col md={12}>
                <FormControl size="small" error={errorVilles} fullWidth className='SelectForamtion bg-input'>
                  <InputLabel htmlFor="grouped-select">- Ville-</InputLabel> 
                  <Select  name="Ville" onChange={ChangeVille} defaultValue="" label="Ville">
                      <MenuItem value="">
                        <em>-Ville-</em>
                      </MenuItem>
                      <MenuItem value="Agadir"> Agadir</MenuItem>
                      <MenuItem value="Ahfir"> Ahfir</MenuItem>
                      <MenuItem value="Alhoceima"> Alhoceima</MenuItem>
                      <MenuItem value="Asilah"> Asilah</MenuItem>
                      <MenuItem value="Azilal"> Azilal</MenuItem>
                      <MenuItem value="Azrou"> Azrou</MenuItem>
                      <MenuItem value="Beni Mellal"> Beni Mellal</MenuItem>
                      <MenuItem value="Berkane"> Berkane</MenuItem>
                      <MenuItem value="Berrechid"> Berrechid</MenuItem>
                      <MenuItem value="Boujaad"> Boujaad</MenuItem>
                      <MenuItem value="Boujdour"> Boujdour</MenuItem>
                      <MenuItem value="Casablanca"> Casablanca</MenuItem>
                      <MenuItem value="Chefchaouen"> Chefchaouen</MenuItem>
                      <MenuItem value="Dakhla"> Dakhla</MenuItem>
                      <MenuItem value="El_Jadida"> El_Jadida</MenuItem>
                      <MenuItem value="Erfoud"> Erfoud</MenuItem>
                      <MenuItem value="Er_rachidia"> Er_rachidia</MenuItem>
                      <MenuItem value="Essaouira"> Essaouira</MenuItem>
                      <MenuItem value="Fes"> Fes</MenuItem>
                      <MenuItem value="Figuig"> Figuig</MenuItem>
                      <MenuItem value="Fquih ben saleh"> Fquih ben saleh</MenuItem>
                      <MenuItem value="Guelmim"> Guelmim</MenuItem>
                      <MenuItem value="Ifrane"> Ifrane</MenuItem>
                      <MenuItem value="Jerrada"> Jerrada</MenuItem>
                      <MenuItem value="Kasbat tadla"> Kasbat_tadla</MenuItem>
                      <MenuItem value="Kenitra"> Kenitra</MenuItem>
                      <MenuItem value="Khemisset"> Khemisset</MenuItem>
                      <MenuItem value="Khenifra"> Khenifra</MenuItem>
                      <MenuItem value="Khouribga"> Khouribga</MenuItem>
                      <MenuItem value="Ksar el kbir"> Ksar el kbir</MenuItem>
                      <MenuItem value="Laayoune"> Laayoune</MenuItem>
                      <MenuItem value="Lagouira"> Lagouira</MenuItem>
                      <MenuItem value="Larache"> Larache</MenuItem>
                      <MenuItem value="Marrakech"> Marrakech</MenuItem>
                      <MenuItem value="Meknes"> Meknes</MenuItem>
                      <MenuItem value="Merzouga"> Merzouga</MenuItem>
                      <MenuItem value="Midelt"> Midelt</MenuItem>
                      <MenuItem value="Mohammedia"> Mohammedia</MenuItem>
                      <MenuItem value="Moulay Bousselham"> Moulay Bousselham</MenuItem>
                      <MenuItem value="Nador"> Nador</MenuItem>
                      <MenuItem value="Oualidia"> Oualidia</MenuItem>
                      <MenuItem value="Ouarzazate"> Ouarzazate</MenuItem>
                      <MenuItem value="Ouedzem"> Ouedzem</MenuItem>
                      <MenuItem value="Ouazzane"> Ouazzane</MenuItem>
                      <MenuItem value="Oujda"> Oujda</MenuItem>
                      <MenuItem value="Oulmes"> Oulmes</MenuItem>
                      <MenuItem value="Rabat"> Rabat</MenuItem>
                      <MenuItem value="Rissani"> Rissani</MenuItem>
                      <MenuItem value="Safi"> Safi</MenuItem>
                      <MenuItem value="Sale"> Salé</MenuItem>
                      <MenuItem value="Sefrou"> Sefrou</MenuItem>
                      <MenuItem value="Settat"> Settat</MenuItem>
                      <MenuItem value="Sidi ifni"> Sidiifni</MenuItem>
                      <MenuItem value="Sidi kacem"> Sidi kacem</MenuItem>
                      <MenuItem value="Sidi slimane"> Sidi slimane</MenuItem>
                      <MenuItem value="Tanger"> Tanger</MenuItem>
                      <MenuItem value="Tantan"> Tantan</MenuItem>
                      <MenuItem value="Taroudant"> Taroudant</MenuItem>
                      <MenuItem value="Tata"> Tata</MenuItem>
                      <MenuItem value="Taza"> Taza</MenuItem>
                      <MenuItem value="Temara"> Temara</MenuItem>
                      <MenuItem value="Tetouan"> Tétouan</MenuItem>
                      <MenuItem value="Tiflet"> Tiflet</MenuItem>
                      <MenuItem value="Tineghir"> Tineghir</MenuItem>
                      <MenuItem value="Tiznit"> Tiznit</MenuItem>
                      <MenuItem value="Zagora"> Zagora</MenuItem>
                      <MenuItem value="Autres"> Autres</MenuItem>
                  </Select>
                  <FormHelperText className="text-danger">{errorVilles}</FormHelperText>
                  </FormControl>
                </Col>
                <Col md={12}>
                <FormControlLabel className="checkPolitique politique politiqueText" control={<Checkbox value={politique} onChange={HandleChangePolitique} />} 
                label={
                  <p className="m-0 politiqueText"> J’ai lu et j’accepte la <Politique /></p>
                }/>
                <FormHelperText className="text-danger">{errorPolitique}</FormHelperText>
                </Col>
            </Row>
                  <div className="mx-auto text-center mt-1">
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="w-80 text-end btnSubmit"
                            >
                            S'inscrire
                          </Button>
                    </div>
                  </>
                    }
    </form>
  )
}

export default FormMedcin