import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import React, { useEffect,useState } from 'react'

const NiveauSouhaite = ({formation_id,changeNiveau,errorNiveau}) => {
    const [niveau,setNiveau]=useState([])
    const [niveauCrm,setNiveauCrm] = useState('')
    const [niveauLp,setNiveauLp] = useState('')
    useEffect(()=>{
        console.log(`formation Numero ${formation_id}`)
        if(formation_id){
            axios.get(`https://lemonmind.agency/lpupmbackend/niveau.php?id=${formation_id}`)
            .then(res=>{
                setNiveau(res.data);
            })
            .catch(err=>console.log(err))
        }else{
            setNiveau([])
        }
    },[formation_id])
    const handleChange=(e)=>{
        const formation=e.target.value.split(',')
        setNiveauCrm(formation[0])
        setNiveauLp(formation[1])
        changeNiveau(formation[0])
        console.log("Niveau "+formation)
    }
  return (
    <div>
        <input type="text" className='d-none' name="niveau crm" value={niveauCrm} /> 
        <input type="text" className='d-none' name="niveau" value={niveauLp} /> 
           <FormControl size="small" error={errorNiveau} fullWidth className="SelectForamtion bg-input">
            <InputLabel htmlFor="grouped-select">Niveau souhaité</InputLabel>
            <Select onChange={handleChange} id="grouped-select" label="Niveau souhaité">
             {niveau.map((pole) => (
              <MenuItem value={pole.correspondance_crm+","+pole.name} key={pole.id}>
                  {pole.name}
             </MenuItem> 
              ))}
            </Select>
        </FormControl>
        <FormHelperText className="text-danger">
                {errorNiveau}
        </FormHelperText>
    </div>
  )
}

export default NiveauSouhaite