import React from 'react'
import { Container,Row,Col }  from 'react-bootstrap'
import "../styles/Banner.css"
import NewFormStepOne from './NewFormStepOne'
import upmHeaderLandingPage from '../images/upm header landing page.png'

const Banner = () => {
  return (
    <>
        <Container>
            <Row className='align-items-center justify-content-between'>
                <Col md={6}>
                        {/* <div className="formationIcon">
                            <span className='f1'>
                                ingénire
                            </span>
                            <span className="f2">
                                management
                            </span>
                            <span className="f3">
                                hotellierie
                            </span>
                        </div>    
                        <div className="formationIcon">
                            <span className="f4">
                                media/art
                            </span>
                            <span className="f5">
                                sport
                            </span>
                            <span className="f6">
                                santé
                            </span>
                        </div> */}
                        <img src={upmHeaderLandingPage} alt='Upm Pole' width="100%" />
                </Col>
                <Col md={5} className="FormMobileMobile">
                    <h3 className="text-center">Je candidate</h3>
                    <NewFormStepOne />
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Banner