import React, { useEffect } from 'react'
import { Row,Container,Col } from 'react-bootstrap'
import "../styles/WhyUpm.css"
import AOS from 'aos';
import 'aos/dist/aos.css'
import NewFormStepOne from './NewFormStepOne'
import check from "../images/icons/badge-check.png"
import { Link } from 'react-scroll'
import Button from '@mui/material/Button';
const WhyUpm = () => {
    useEffect(()=>{
      AOS.init()  
    })
  return (
    <>
    <div className="WhyUpm mb-3">
        <Container>
            <Row className="align-items-center">
                <Col md={6} className="mt-5 pourqouiUpm">
                    <span className='subTitle'>
                    Pourquoi nous choisir ?
                    </span> 
                    <h2>Université Privée de Marrakech</h2>
                    <p className="contenu mb-0">
                    L’Université Privée de Marrakech, université reconnue par l’Etat est un acteur majeur dans l’enseignement supérieur au Maroc et en Afrique. <br />
                    Les enseignements dispensés à l’UPM sont organisés autour de 7 pôles de formation : 
                    </p>
                        <div>
                        <Row className="mt-3 ListOfFormation">
                            <Col md={7} className="mt-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Tourisme, Hôtellerie et Art de Vivre
                                </div>
                            </Col>
                            <Col md={5} className="mt-2">
                            <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Médecine
                                </div>
                            </Col>    
                        </Row>
                        <Row>
                            <Col md={7} className="mt-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Business & Gouvernance
                                </div>
                            </Col>
                            <Col md={5} className="mt-2">
                            <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Sciences de la Santé
                                </div>
                            </Col>    
                        </Row>
                        <Row>
                            <Col md={7}  className="mt-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Ingénierie et Innovation
                                </div>
                            </Col>
                            <Col md={5} className="mt-2">
                            <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Sport et Carrières
                                </div>
                            </Col>    
                        </Row>
                        <Row >
                            <Col md={7} className="mt-2">
                                <div className="d-flex justify-content-start align-items-center">
                                    <img src={check} className="mx-2" alt="" width="25px" />
                                    Arts, Culture, Médias et Audiovisuel
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <p className="contenu mt-5 ListOfFormation contenuBas">
                    Les diplômes Licence, Master, Ingénieur et Doctorat de Médecine de l'UPM, sont équivalents aux diplômes d'Etat.<br />
                    Faites le choix de votre programme et envoyez votre demande d’admission à l’UPM en complétant ce formulaire.
                    </p>
                    <Link to="contacter" className='text-center mb-3 text-decoration-none'>
                        <Button variant="contained" className="btnForm  mt-3">INSCRIVEZ-VOUS</Button>
                    </Link>
                    {/* <ul>

                            <li>Tourisme, Hôtellerie & Art de Vivre </li>
                            <li>Business & Gouvernance </li>
                            <li>Ingénierie & Innovation </li>
                            <li>Médecine</li>
                            <li>Santé</li>
                            <li>Sport</li>
                            <li>Digital, Médias, Arts & Culture</li>
                    </ul> */}
                    {/* <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div>
                    <div className="d-flex justify-content-start align-items-center">
                        <img src={Education} className="mx-2" alt="" width="45px" />
                    </div> */}
                
                 
                </Col>
                <Col md={5} className="formWhy">
                    <h3 className="text-center">Je candidate</h3>
                    <NewFormStepOne />
                </Col>
            
            </Row>
        </Container>
    </div>
    </>
  )
}

export default WhyUpm