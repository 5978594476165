import React from 'react'
import { Navbar,Container } from 'react-bootstrap'
import logo from '../images/logo.png'
import "../styles/Header.css"

const Header = () => {
  return (
    <>
        <Navbar>
            <Container>
                <Navbar.Brand href="#home">
                    <img  src={logo} alt="logo upm" width="220px" className='logoUpm'/>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
  )
}

export default Header