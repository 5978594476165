import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import "../styles/Footer.css"
import facebookIcon from "../images/icons/fb.png"
import youtubeIcon from "../images/icons/youtube.png"
import insIcon from "../images/icons/ins.png"
import twitter from "../images/icons/twitter.png"
const Footer = () => {
  return (
    <div className="footer">
        <Container>
            <Row className="align-items-center">
                <Col md={5} sm={12} >
                    <span>
                        © 2022 UPM - TOUS DROITS RÉSERVÉS
                    </span>
                </Col>
                <Col md={7} sm={12} className="text-end iconFooter">
                    {/* <a href="https://youtu.be/TtAjzWVZ5iU" target="_blank"> */}
                        <img src={youtubeIcon} alt="Socail Media Youtube"/>
                    {/* </a> */}
                    {/* <a href="https://www.facebook.com/UPMarrakech" target="_blank"> */}
                        <img src={facebookIcon} alt="Socail Media Facebook"/>
                    {/* </a> */}
                    {/* <a href="https://www.instagram.com/upmarrakech" target="_blank"> */}
                        <img src={insIcon} alt="Socail Media Instagram"/>
                    {/* </a> */}
                    {/* <a href="https://twitter.com/UPMarrakech" target="_blank"> */}
                        <img src={twitter} alt="Socail Media Twitter"/>
                    {/* </a> */}
                </Col>
            </Row>

        </Container>
    </div>
  )
}

export default Footer